/* A module containing functions for event handlers, UI helpers, and other useful
things. Functions that aren't exported from this module start with an underscore,
like this: _doStuff, and are at the top of the file. */

import * as constants from './constants.js';
import * as layers from './layers.js';
import map from './map.js';
import * as sources from './sources.js';

// DOM elements that we use repeatedly in this file
const _collapsibleButtons = document.getElementsByClassName('header-button');
const _colorLegend = document.getElementById('hex-colors');
const _controls = document.getElementsByClassName('control_ui');
const _consoleElement = document.getElementById('console');
const _electrification = document.getElementById('electrification');
const _hexAreaLabel = document.getElementById('hex-area');
const _hexLevelLabel = document.getElementById('hex-level');
const _layerSelect = document.getElementById('layer-select');
const _legend = document.getElementById('legend');
const _mwhFilterMin = document.getElementById('mwh-filter-min');
const _mwhFilterMax = document.getElementById('mwh-filter-max');
const _slider = document.getElementById('slider');
const _sliderButtons = document.getElementsByClassName('slider_button');
const _sliderLabel = document.getElementsByClassName('slider_label')[0];
const _yearLabel = document.getElementById('active-year');

// make a step function for class colors applying the supplied breaks
function _compileStepFunction(breaks, colors) {
    let steps = [
        'step',
        ['get', constants.hexAttribute],
        'rgba(0,0,0,0)',
        constants.minValueForHexDisplay,
    ];
    for (let i = 0; i < 4; i++) {
        steps.push(colors[i]);
        steps.push(breaks[i]);
    }
    steps.push(colors[4]);
    return steps;
}

// A helper function that loads the right map tiles for a given year
// year: probably a string but it doesn't matter in javascript
function _loadYear(year) {
    let dynamicTileUrl = sources.dynamicTileSource.url;
    let staticTilesUrl = sources.staticTileSource.url;

    if (year) {
        dynamicTileUrl = `${sources.dynamicTileSource.url}?yyyy=${year}`;
        staticTilesUrl = `${constants.staticTilesBase}/${year}/{z}/{x}/{y}.pbf`;
    } else {
        staticTilesUrl = `${constants.staticTilesBase}/100/{z}/{x}/{y}.pbf`;
    }
    map.getSource(sources.dynamicTileSource.id).setTiles([dynamicTileUrl]);
    map.getSource(sources.staticTileSource.id).setTiles([staticTilesUrl]);
    updateHexResolution();
}

// Mapbox currently blows away all of the sources and layers you added when you switch styles.
// But you can copy them over from one to another, because styles are just json objects.
// This function grabs a style json spec from a URL and copies over the sources and layers
// we added to the default style, so that it can work correctly.
// Taken from https://github.com/mapbox/mapbox-gl-js/issues/4006#issuecomment-1114095622
// styleID: the ID, in Mapbox, of the relevant style. We use constants like satelliteStyleId and RoadStyleId for these.
async function _buildStyleFromCurrent(styleID) {
    const response = await fetch(
        `https://api.mapbox.com/styles/v1/${styleID}?access_token=${mapboxgl.accessToken}`
    );
    const responseJson = await response.json();
    const newStyle = responseJson;

    const currentStyle = map.getStyle();
    // ensure any sources from the current style are copied across to the new style
    newStyle.sources = Object.assign(
        {},
        currentStyle.sources,
        newStyle.sources
    );

    // find the index of where to insert our hex layers to retain in the new style
    let hexIndex = newStyle.layers.findIndex((el) => {
        return el.id == 'null-island';
    });

    // find the index of where to insert our other data layers to retain
    let extraLayerIndex = newStyle.layers.findIndex((el) => {
        return el.id == 'road-label';
    });

    // default to on top
    if (hexIndex === -1) {
        hexIndex = newStyle.layers.length;
    }

    if (extraLayerIndex == -1) {
        extraLayerIndex = newStyle.layers.length;
    }

    const hexLayers = currentStyle.layers.filter((el) => {
        return layers.dataLayers.includes(el.id);
    });

    const otherLayers = currentStyle.layers.filter((el) => {
        return layers.extraLayers.includes(el.id);
    });

    newStyle.layers = [
        ...newStyle.layers.slice(0, hexIndex),
        ...hexLayers,
        ...newStyle.layers.slice(hexIndex, extraLayerIndex),
        ...otherLayers,
        ...newStyle.layers.slice(extraLayerIndex, -1),
    ];
    return newStyle;
}

// It would be nice if we could handle the filter changes with the same
// function, but that causes some infinite recursion problems due to the
// way the validation has to be done.
function _filterMWh(min, max) {
    for (let i in layers.dataLayers) {
        if (isNaN(min) || isNaN(max)) {
            // this wasn't a number. Just clear the filter and reset the UI
            // as a fallback.
            map.setFilter(layers.dataLayers[i], null);
            setupMWhFilter();
        } else {
            map.setFilter(layers.dataLayers[i], [
                'all',
                ['>=', ['get', constants.hexAttribute], min],
                ['<=', ['get', constants.hexAttribute], max],
            ]);
        }
    }
}

/* State variables */

// store state of the full electrification checkbox
let colorRampType = 'years';

/* Public / exported functions start here. */

// A helper to see if the page has finished loading.
function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
        return;
    }
    document.addEventListener('DOMContentLoaded', fn);
}

// Tell us what hex resolution to assume the tiles contain,
// based on the zxy slippy map zoom level
function hexResFromZoom() {
    const z = map.getZoom();
    for (let i in constants.hexResolutionBreaks) {
        if (z < constants.hexResolutionBreaks[i].zoom) {
            return constants.hexResolutionBreaks[i].hex;
        }
    }
    return constants.hexResolutionBreaks.at(-1).hex;
}

// Update the information text about hexagon area as well as map colors based
// on zoom level (which determines hex resolution) and ramp type
function updateHexResolution() {
    const hexRes = hexResFromZoom();
    if (hexRes in constants.hexAreas) {
        _hexLevelLabel.innerText = hexRes;
        _hexAreaLabel.innerText = constants.hexAreas[hexRes];
    }

    const breakPoints = constants.categoryBreaks[colorRampType][hexRes];
    for (let i = 1; i < 6; i++) {
        document.getElementsByClassName('bin' + i)[0].innerText =
            breakPoints[i - 1].toLocaleString('en-US');
    }
    const steps = _compileStepFunction(
        breakPoints,
        constants.categoryColors[colorRampType]
    );
    map.setPaintProperty(layers.staticTilesLayer.id, 'fill-color', steps);
    map.setPaintProperty(layers.dynamicTilesLayer.id, 'fill-color', steps);
}

// Update the year slider and corresponding map filter
// increment: an integer, positive or negative, representing how much to move the slider by
function moveYearSlider(increment) {
    clearpopups();

    const minYear = parseInt(_slider.min, 10);
    const currentYear = parseInt(_slider.value, 10);
    const maxYear = parseInt(_slider.max, 10);

    let desiredYear = currentYear + increment;

    if (desiredYear > maxYear || desiredYear < minYear) {
        desiredYear = currentYear;
        console.log('Hacking too much time');
    }

    // apply the filter
    _loadYear(desiredYear);

    // update text in the UI
    _slider.value = desiredYear;
    _yearLabel.innerText = desiredYear;
}

// Set the main map color legend for the hexes, depending on the range shown in the map
function setHexColorLegend() {
    let gradient = 'background: linear-gradient(to right';
    for (let i = 0; i < 5; i++) {
        const bandColor = constants.categoryColors[colorRampType][i];
        const gradLine = `, ${bandColor} ${i * 20}% ${(i + 1) * 20}%`;
        gradient += gradLine;
    }
    gradient += ');';
    _colorLegend.style.cssText = gradient;
}

// Set the hosting capacity color legend for the lines
function setHostingLegend() {
    let gradient = 'background: linear-gradient(to right';
    for (let i = 0; i < constants.hostingCapacityColors.length; i++) {
        const bandColor = constants.hostingCapacityColors[i];
        const gradLine = `, ${bandColor} ${i * 25}% ${(i + 1) * 25}%`;
        gradient += gradLine;
    }
    gradient += ');';
    document.getElementById('hosting_capacity-colors').style.cssText = gradient;
}

// Set the PM 2.5 and other color legends
// todo: if this becomes stepped rather than a smooth gradient, turn this and the
// two functions above it into one function that takes 2 parameters.
function setLayerGradientColorLegends() {
    let gradient = 'background: linear-gradient(to right, #FFFFFF 0';
    for (let i = 0; i < constants.layerGradientColors.length; i++) {
        const bandColor = constants.layerGradientColors[i] + 'CC';
        const gradLine = `, ${bandColor} ${(i + 1) * 50}%`;
        gradient += gradLine;
    }
    gradient += ');';
    const colorElements = document.getElementsByClassName('layer-gradient-colors');
    for(let i = 0; i < colorElements.length; i++ ) {
        colorElements.item(i).style.cssText = gradient;
    }
}

// Initialize the MWh filter according to the constraings currently in the data
function setupMWhFilter() {
    const max =
        constants.categoryBreaks[colorRampType][hexResFromZoom()].at(-1);
    _mwhFilterMin.max = max;
    _mwhFilterMax.max = max;
    _mwhFilterMax.value = max;
    _mwhFilterMin.value = 0;
}

// An event handler for the 'full electrification' checkbox.
// element: the DOM element that raised the event (the checkbox in question)
function electrificationHandler() {
    clearpopups();

    if (this.checked) {
        // disable the slider and buttons
        // and set the color ramp & tile source to full electrification
        colorRampType = 'fullElectrification';
        _loadYear();
        _slider.disabled = true;
        for (let i = 0; i < _sliderButtons.length; i++) {
            _sliderButtons[i].disabled = true;
        }
        _sliderLabel.classList.add('disabled');
        setHexColorLegend();
    } else {
        // enable the slider and buttons
        // set the color ramp to the years type
        // and set the tile source to whatever the slider's value is
        colorRampType = 'years';
        const currentYear = parseInt(_slider.value, 10);
        _loadYear(currentYear);
        slider.disabled = false;
        _sliderLabel.classList.remove('disabled');

        for (let i = 0; i < _sliderButtons.length; i++) {
            _sliderButtons[i].disabled = false;
        }
        setHexColorLegend();
    }
}

// Dismisses the instructional overlay that appears on page load
function dismissInstructions() {
    document.getElementById('instructions').classList.add('hidden');
    document.body.classList.remove('no-scroll');
}

// For mobile view only, where people can show either the legend or the page controls
// This is an event handler for the button that does that.
// element: the DOM element for the button that is used to toggle this view
function toggleLegend() {
    // toggle button text
    if (_legend.classList.contains('hide-for-small')) {
        _legend.classList.remove('hide-for-small');
        for (let i = 0; i < _controls.length; i++) {
            _controls[i].classList.add('hide-for-small');
        }
        this.innerHTML = 'Show Controls';
    } else {
        _legend.classList.add('hide-for-small');
        for (let i = 0; i < _controls.length; i++) {
            _controls[i].classList.remove('hide-for-small');
        }
        this.innerHTML = 'Show Legend';
    }
}

// For mobile view only, a button that allows people to collapse the portion
// of the UI that can show the legend / page controls.
// This is an event handler for the button that does that.
// element: the DOM element for the button that is used to toggle this view
function toggleControls() {
    if (_consoleElement.classList.contains('collapsed')) {
        _consoleElement.classList.remove('collapsed');
        this.setAttribute('title', 'Close this Panel');
        this.classList.remove('arrow--up');
        this.classList.add('arrow--down');
    } else {
        _consoleElement.classList.add('collapsed');
        this.setAttribute('title', 'Open this Panel');
        this.classList.add('arrow--up');
        this.classList.remove('arrow--down');
    }

    // the contols use a CSS transition that takes time to resize, so
    // resize the map after we know that is finished
    const center = map.getCenter();
    setTimeout(function () {
        map.resize();
        map.setCenter(center);
    }, 510);
}
// A handler for the view toggle button on the map, which switches between
// OSM road and satellite views.
// element: the DOM element for the button that is used to toggle the map style
function toggleStyle() {
    const currentName = map.getStyle().name;

    if (currentName == constants.roadStyleName) {
        this.innerHTML = 'Default View';
        // now fetch our satellite style and put the layers and sources in that too
        _buildStyleFromCurrent(constants.satelliteStyleId).then((res) => {
            map.setStyle(res);
        });
        // set our layer opacities accordingly
        map.setPaintProperty(
            layers.staticTilesLayer.id,
            'fill-opacity',
            constants.satelliteHexFillOpacity
        ).setPaintProperty(layers.dynamicTilesLayer.id, 'fill-opacity', [
            'step',
            ['zoom'],
            0,
            constants.staticHexTilesMaxZoom,
            constants.satelliteHexFillOpacity,
        ]);
    } else {
        this.innerHTML = 'Satellite View';
        _buildStyleFromCurrent(constants.roadStyleId).then((res) => {
            map.setStyle(res);
        });
        // set our layer opacities accordingly
        map.setPaintProperty(
            layers.staticTilesLayer.id,
            'fill-opacity',
            constants.roadHexFillOpacity
        ).setPaintProperty(layers.dynamicTilesLayer.id, 'fill-opacity', [
            'step',
            ['zoom'],
            0,
            constants.staticHexTilesMaxZoom,
            constants.roadHexFillOpacity,
        ]);
    }

    // apply any filters / settings from the control panel, once the style is loaded
    map.once('style.load', () => {
        electrificationHandler.call(_electrification); // will load year if appropriate
        _filterMWh(
            parseInt(_mwhFilterMin.value, 10),
            parseInt(_mwhFilterMax.value, 10)
        );
    });
}

// For desktop view only; people can expand sections of the control panel on the left
// This is a handler for the expandable text headers that allow this
// element: the DOM Element that is used to expand or collapse this view (e.g. "Map Instructions")
function accordion() {
    // Cast the expanded state as a boolean
    let expanded =
        this.getAttribute('aria-expanded') === 'false' ? false : true;

    // find the element to expand / contract
    let expandable = document.getElementById(
        this.getAttribute('aria-controls')
    );

    // Switch the states of aria-expanded and aria-hidden
    this.setAttribute('aria-expanded', !expanded);
    expandable.setAttribute('aria-hidden', expanded);

    // Switch the appearance of the expandable element
    if (expanded) {
        expandable.classList.add('hidden');
    } else {
        expandable.classList.remove('hidden');
    }
}

// A function to filter the minimun MWh to show hexes for
function filterMinMWh() {
    // This is a minimum value to filter on, so it needs to be smaller
    // than the number in the other box
    const max = parseInt(_mwhFilterMax.value, 10);
    this.max = max;

    const valid = this.checkValidity();
    if (valid) {
        let value = parseInt(this.value, 10);

        // if it's not a number, clear the filter.
        if (isNaN(value)) {
            value = parseInt(this.min, 10);
            this.value = value;
        }
        _filterMWh(value, max);
    }
    this.reportValidity();
}

// A function to filter the maximum MWh to show hexes for
function filterMaxMWh() {
    // This is a maximum value to filter on, so it needs to be larger
    // than the number in the other box.
    const min = parseInt(_mwhFilterMin.value, 10);
    this.min = min;

    const valid = this.checkValidity();
    if (valid) {
        let value = parseInt(this.value, 10);

        // if it's not a number, clear the filter.
        if (isNaN(value)) {
            value = parseInt(this.max, 10);
            this.value = value;
        }
        _filterMWh(min, value);
    }
    this.reportValidity();
}

// A function to show the relevant data layer (in extraLayers)
// This is the selection handler for the layer switcher.
function showLayer() {
    clearpopups();
    // show the selected layer, hide all the other layers
    for (let x in layers.extraLayers) {
        const layer = layers.extraLayers[x];
        if (!!this.value && layer.startsWith(this.value)) {
            _legend.getElementsByClassName(layer)[0].classList.remove('hidden');
            map.setLayoutProperty(layer, 'visibility', 'visible');
        } else {
            _legend.getElementsByClassName(layer)[0].classList.add('hidden');
            map.setLayoutProperty(layer, 'visibility', 'none');
        }
    }
}

// helper to clear popups on the map
function clearpopups() {
    document.querySelectorAll('.mapboxgl-popup').forEach((e) => e.remove());
}

// Helper to generate a url for the current view and copy it to the user's clipboard
function copyShareLink() {
    const message = this.innerHTML;
    const successClass = 'action_button--success';
    const shareURL = buildCurrentStateUrlParams().toString();
    history.replaceState({}, '', shareURL);
    navigator.clipboard.writeText(shareURL);

    this.innerHTML = 'Link Copied';
    this.classList.add(successClass);

    // display a success message for 5 seconds
    setTimeout(
        function (button) {
            button.innerHTML = message;
            button.classList.remove(successClass);
        },
        5000,
        this
    );
}

// Helper to hook up the event handlers in this file to their respective UI elements
function setupEventHandlers() {
    document.getElementById('view-map').onclick = dismissInstructions;
    document.getElementById('toggle-style').onclick = toggleStyle;
    document.getElementById('toggle-legend').onclick = toggleLegend;
    document.getElementById('toggle-controls').onclick = toggleControls;
    _slider.onmouseup = function () {
        moveYearSlider(0);
    };
    document.getElementById('slider_back').onclick = function () {
        moveYearSlider(-1);
    };
    document.getElementById('slider_forward').onclick = function () {
        moveYearSlider(1);
    };
    document.getElementById('share-button').onclick = copyShareLink;
    _electrification.onclick = electrificationHandler;
    _mwhFilterMin.onfocusout = filterMinMWh;
    _mwhFilterMin.onchange = filterMinMWh;
    _mwhFilterMax.onfocusout = filterMaxMWh;
    _mwhFilterMax.onchange = filterMaxMWh;
    _layerSelect.onchange = showLayer;
    Array.from(_collapsibleButtons).forEach(function (el) {
        el.onclick = accordion;
    });
}

// Helper to apply url parameters to UI elements
function applyParamValues(cleanedParams) {
    // initialise the year slider (or set the year if the page param indicates)
    if (cleanedParams.hasOwnProperty('year')) {
        const year = parseInt(cleanedParams['year'], 10);
        slider.value = year;
        _loadYear(year);
    }
    moveYearSlider(0); // This sets up some necessary stuff

    // if the page parameter indicates, check the 'full electrification' box
    // and call its event handler
    if (
        cleanedParams.hasOwnProperty('fullElectrification') &&
        cleanedParams['fullElectrification'] == 'true'
    ) {
        _electrification.checked = true;
        electrificationHandler.apply(_electrification);
    }

    // if the page parameter indicates, select a layer and call the
    // event handler
    if (cleanedParams.hasOwnProperty('layer')) {
        _layerSelect.value = cleanedParams['layer'];
        showLayer.apply(_layerSelect);
    }
}

// Helper to build a url with parameters that reflect the current page state
function buildCurrentStateUrlParams() {
    const params = new URLSearchParams({
        year: slider.value,
        fullElectrification: _electrification.checked,
        layer: _layerSelect.value,
        zoom: map.getZoom(),
        center: map.getCenter().toArray(),
    });
    let stateURL = new URL(window.location.href);
    stateURL.search = params;
    return stateURL;
}

export {
    accordion,
    applyParamValues,
    clearpopups,
    colorRampType,
    dismissInstructions,
    electrificationHandler,
    filterMaxMWh,
    filterMinMWh,
    hexResFromZoom,
    moveYearSlider,
    ready,
    setHexColorLegend,
    setHostingLegend,
    setLayerGradientColorLegends,
    setupEventHandlers,
    setupMWhFilter,
    showLayer,
    toggleLegend,
    toggleStyle,
    updateHexResolution,
};
